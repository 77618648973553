import React from "react";
import styles from "./PortfolioLink.module.css";

const displayPortfolio = userid => {
  window.open(`/portfolio/${userid}`, "_blank");
};

const PortfolioLink = ({ data: { userid, name, privilege } }) => {
  return (
    <span data-cy="portfolio-item" onClick={() => displayPortfolio(userid)} className={styles.item + " " + (privilege?.includes("VIP") || privilege?.includes("NEW") ? styles.vip : "")}>
      {name}
    </span>
  );
};

export default PortfolioLink;
